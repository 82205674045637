import * as React from "react";
import { useState, useEffect } from "react";
import Layout from "../components/layout";
import BannerSlider from "../components/Banner/BannerSlider";
import H3 from "../utils/H3";
import H6 from "../utils/H6";
import Button from "../utils/Button";
import { motion, AnimatePresence } from "framer-motion";
import { FaReact } from "@react-icons/all-files/fa/FaReact";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { RiTeamFill } from "@react-icons/all-files/ri/RiTeamFill";
import { IoApertureOutline } from "@react-icons/all-files/io5/IoApertureOutline";
import { GiMountaintop } from "@react-icons/all-files/gi/GiMountaintop";
import { BsFlagFill } from "@react-icons/all-files/bs/BsFlagFill";
import parse from "html-react-parser";
import CounterSection from "../components/CounterSection";
import Favicon from "../images/favicon.svg";
import TestimonialSlider from "../components/TestimonialSlider";
import ReactPlayer from "react-player/youtube";
import TeacherTile from "../components/TeacherTile";
import Seo from "../components/seo";
import Our4c from "../components/Courses/Our4c";
import WhySectionSlider from "../components/slider/why-section";
const IndexPage = () => {
  const whyContent = [
    {
      id: 1,
      title: "Learning Practical and Theory",
      image: "/images/practical.png",
      description:
        "Performance and Theory, are both essential in any art form. Thus, we focus on teaching this simultaneously, for the students to work and think like artists, right from the beginning, unlike other dance schools. ",
      icon: "<FaReact/>",
    },
    {
      id: 2,
      title: "Progress Tracking",
      image: "/images/progress-tracking.png",
      description:
        "Progress Tracking at Kaladhwani is conducted on a quarterly basis. This is divided into 3 steps : Video Recording the performance; Reviewing Student Knowledge; Assessment between then and now. Progress Tracking is not looked at as a goal, but a positive progress of the learning journey of a child.",
      icon: "<RiTeamFill/>",
    },
    {
      id: 3,
      title: "Workshops at Kaladhwani",
      image: "/images/workshop.png",
      description:
        "It is not just classroom teaching. The Workshops organised are free for active students enrolled at Kaladhwani. These range from Movement, Rhythm, Music, Poetry, Mythology and Aesthetics, which will broaden their perspective.",
      icon: "<IoApertureOutline/>",
    },
    {
      id: 4,
      title: "One-on-One Mentorship",
      image: "/images/mentorship.png",
      description:
        "We encourage and are happy to guide, if a student wishes to perform a composition (at a festival or an event), he/she will be given individual mentorship for the performance.",
      icon: "<FaGlobe/>",
    },
    {
      id: 5,
      title: "Meet the Legends",
      image: "/images/legends.png",
      description:
        "For the evolution of the child, emphasis is not just on teaching at Kaladhwani, but to make the child aware of the history and tradition of each art form. The students will get an opportunity to interact  and get inspired by these legends and learn the nuances of the form.",
      icon: "<GiMountaintop/>",
    },
    {
      id: 6,
      title: "KALARATI - Annual Showcase",
      image: "/images/performance.png",
      description:
        "We take pride in our students' learning. Keeping this in view, an Annual showcase is organised to provide a platform to the students. We ensure they experience the performance process and specifically train them for competitive as well as cultural events.",
      icon: "<BsFlagFill/>",
    },
  ];
  const renderSwitch = (param, rowId, currentRowId) => {
    switch (param) {
      case "<FaReact/>":
        return (
          <FaReact
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      case "<RiTeamFill/>":
        return (
          <RiTeamFill
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      case "<IoApertureOutline/>":
        return (
          <IoApertureOutline
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      case "<FaGlobe/>":
        return (
          <FaGlobe
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      case "<GiMountaintop/>":
        return (
          <GiMountaintop
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      case "<BsFlagFill/>":
        return (
          <BsFlagFill
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
      default:
        return (
          <FaReact
            className={`${
              rowId === currentRowId
                ? "w-6 h-6 text-white"
                : "w-5 h-5 text-black"
            }`}
          />
        );
    }
  };

  const numbers = [1, 2, 3, 4, 5, 6];
  const delay = 2500;
  const [sliderIndex, setSliderIndex] = React.useState(0);
  const [currentRow, setCurrentRow] = useState(whyContent[sliderIndex]);
  const timeoutRef = React.useRef(null);

  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  React.useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(
      () =>
        setSliderIndex((prevIndex) =>
          prevIndex === numbers.length - 1 ? 0 : prevIndex + 1
        ),
      delay
    );
    setCurrentRow(whyContent[sliderIndex]);
    return () => {
      resetTimeout();
    };
  }, [sliderIndex]);
  return (
    <Layout>
      <Seo
        title="Live Online Bharatanatyam, Hindustani Music classes for all kids and adults"
        url="https://kaladhwani.com"
        image={Favicon}
      />
      <BannerSlider />
      <div className="flex justify-center flex-col w-full items-center bg-white py-16">
        <H3 uppercase color="text-black">
          Our Art Forms
        </H3>
        <div className="grid md:grid-cols-2 grid-cols-1 md:grid-rows-1 grid-rows-2 gap-4 lg:w-7/12 w-full lg:px-0 md:px-12 px-4">
          <div className="relative bg-bharatnatyamTile bg-contain bg-no-repeat bg-bottom bg-orange md:h-72 h-60 w-full rounded-lg p-4 flex justify-center flex-col w-full items-end h-full">
            <div className="flex flex-col justify-center w-6/12 items-end h-full">
              <H3
                uppercase
                color="text-black"
                className="text-right"
                bottom="mb-0"
              >
                Learn <br />
                Bharatanatyam
              </H3>
              <H6 color="text-black" className="text-right font-normal mb-12">
                Age 6-60
              </H6>
              <Button type="internalLink" to="/learn-bharatanatyam-online">
                LEARN MORE
              </Button>
            </div>
            <p
              className="text-xs absolute top-auto bottom-2 right-4"
              style={{ color: "#7E7E7E" }}
            >
              Image Credit: Kiron Pasricha
            </p>
          </div>
          <div className="bg-hindustaniTileBg bg-contain md:h-72 h-60 w-full rounded-lg relative bg-orange relative overflow-hidden">
            <img
              src="/images/learn-hindustani.png"
              alt=""
              className="object-contain"
            />
            <div className="flex justify-center flex-col w-full items-end absolute top-0 right-0 h-full w-6/12 p-4">
              <H3
                uppercase
                color="text-black"
                className="text-right"
                bottom="mb-0"
              >
                Learn <br />
                Hindustani Music
              </H3>
              <H6 color="text-black" className="text-right font-normal mb-4">
                Age 6-60
              </H6>
              <Button type="internalLink" to="/learn-hindustani-music-online">
                LEARN MORE
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-darkmustard w-full h-auto lg:px-28 md:px-12 px-4 pt-12 pb-8 bg-whyLearn md:bg-center bg-top bg-cover bg-no-repeat flex flex-col">
        <H3 color="text-white" className="text-center" bottom="mb-4" uppercase>
          WHY LEARN AT KALADHWANI?
        </H3>
        <p className="text-center text-base lg:w-8/12 m-auto text-white">
          Kaladhwani’s learning modules are designed by practitioners with 15+
          years of experience in their respective fields. Adept in both,
          Performing and Teaching, the artists are graded by the Government of
          India. They have learnt from eminent gurus, performed globally and
          have an experience of teaching students across varied age groups.
        </p>
        <div className="lg:flex-row flex-col flex mt-6 relative">
          <div className="md:relative sticky md:top-0 top-16 z-10 flex justify-center flex-col items-center relative lg:w-476px md:w-8/12 md:mb-8 lg:mb-0 w-full">
            <img
              src="/images/why-border.svg"
              alt=""
              className="h-auto w-auto"
            />
            <div className="absolute left-0 top-0 w-full h-full flex px-8 py-8 justify-center flex-col items-center">
              <AnimatePresence exitBeforeEnter>
                <motion.img
                  key={currentRow ? currentRow.id : "empty"}
                  animate={{ opacity: 1, y: 0 }}
                  initial={{ opacity: 0, y: 20 }}
                  exit={{ opacity: 0, y: -20 }}
                  transition={{ duration: 0.15 }}
                  src={currentRow.image}
                  alt=""
                  className="w-full object-contain rounded-3xl"
                />
              </AnimatePresence>
            </div>
          </div>
          {/* <div className="justify-center items-center md:hidden flex md:w-10/12 w-full mt-12 md:mt-0 lg:pl-8 pl-4">
            <WhySectionSlider></WhySectionSlider>
          </div> */}
          <div className="lg:w-8/12 md:w-10/12 w-full mt-12 md:mt-0 lg:pl-8 pl-4">
            {whyContent.map((row, index) => (
              <div
                className="flex lg:h-auto md:h-28 h-42 cursor-pointer learnRow"
                onClick={() => setSliderIndex(index)}
              >
                <div className="flex flex-col justify-start items-center tab w-12 relative">
                  <div
                    className={`${
                      row.id == numbers[sliderIndex]
                        ? "w-12 h-12 bg-black"
                        : "w-8 h-8 bg-lightOrange"
                    } z-1 transition-all duration-300 ease rounded-full flex justify-center flex-col items-center`}
                  >
                    {renderSwitch(row.icon, row.id, numbers[sliderIndex])}
                  </div>
                  <div className="absolute left-6 z-0 h-full w-1px bg-white line"></div>
                </div>
                <div className="flex flex-col pl-8 group w-tabRow">
                  <div
                    className={`${
                      row.id == numbers[sliderIndex] ? "h-12" : "h-8"
                    } flex items-start transition-all duration-300 ease`}
                  >
                    <h3
                      className={`font-bold transition-all duration-300 ease w-auto group-hover:text-black mt-0 md:mt-0 ${
                        row.id == numbers[sliderIndex]
                          ? "text-xl text-black h-12"
                          : "md:text-lg text text-white h-8"
                      } md:leading-snug leading-none flex items-center`}
                    >
                      {row.title}
                    </h3>
                  </div>
                  <p
                    className={`text-sm transition-all pb-4 ${
                      row.id == numbers[sliderIndex] ? "md:-mt-2" : "md:mt-0"
                    } leading-none duration-300 ease group-hover:text-black ${
                      row.id == numbers[sliderIndex]
                        ? "text-black"
                        : "text-white"
                    }`}
                  >
                    {row.description}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="bg-white py-12 flex justify-center flex-col items-center">
        <H3 color="text-black" uppercase>
          Hear from our community
        </H3>
        <TestimonialSlider />
      </div>
      <div className="bg-lightOrange py-12 flex justify-center flex-col items-center lg:px-0 md:px-12 px-4">
        <Our4c
          home={true}
          title="Our 4C Curriculum of arts"
          video="https://www.youtube.com/embed/mte7HbzbksM"
        ></Our4c>
      </div>
      {/* teacher section */}
      <div
        className="bg-white py-12 flex justify-center flex-col items-center lg:px-28 md:px-12 px-4"
        id="meet-your-teachers"
      >
        <H3 color="text-black" bottom="mb-4" uppercase>
          MEET YOUR TEACHERS
        </H3>
        <p
          className="text-center text-base md:w-10/12 w-11/12"
          style={{ color: "#808080" }}
        >
          We believe that performing and teaching are two different skill sets,
          and hence we take special care to ensure that we hire highly qualified
          facilitators, who are trained in the form and are dedicated to
          teaching . Thus, our one of a kind 4C Curriculum is a result of
          meticulous planning and hardwork by our founder, which ensures that
          the child expands his/her horizon into the world of arts. Like they
          say, ‘What’s a well begun is half done’.
        </p>
        <div className="md:grid flex flex-col md:grid-cols-2 md:gap-4 mt-12">
          <TeacherTile
            name="Ms. Radhika Kathal"
            title="Bharatanatyam | Hindustani Vocalist"
            description="Ms. Radhika Kathal is the <strong>founder of Kaladhwani.</strong> She is a senior disciple of <strong>Guru (Padmashri & SNA Awardee) Geeta Chandran.</strong> A post-graduate in Hindustani Music from Faculty of Music (University of Delhi), she currently trains under Dr. Soma Singh. Amongst her accomplishments is her performance at Rashtrapati Bhawan when <strong>US President Mr. Barack Obama visited India.</strong><br/><br/>Radhika is a <strong>graded artist by Doordarshan Kendra,</strong> New Delhi and also a recipient of the <strong>National Scholarship from The Ministry of Culture,</strong> Govt. of India. With more than <strong>750+ stage performances</strong> across India and abroad, Radhika is also an Arts Anchor at Shiv Nadar School, Noida."
            image="/images/radhika.jpg"
            credit="Image Credit: Rakesh Sahai"
            link="/learn-bharatanatyam-online"
            buttonText="LEARN WITH RADHIKA"
            follow="Follow Radhika's work:-"
            followLink="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g"
            pdf="/radhika-kathal-bio.pdf"
          />
          <TeacherTile
            name="Ms. Tara Kannan"
            title="Hindustani Vocalist"
            description="Tara Kannan has been pursuing music since the age of 10. Initially trained under Smt. Ketaki Marathe and Shri Mukund Marathe, she further honed her skills under Pt. Pradeep Natekar (A stalwart exponent of Agra, Gwalior and Jaipur Gharana). <br/><br/>A Gold medalist and A University topper, both in Masters and MPhil from the prestigious Faculty of Music, she is also a recipient of the prestigious ‘National Scholarship’ by Ministry Of Culture, Govt. of India. Currently, she is training under Prof.Ojesh Pratap Singh ( a disciple of Padma Shri Pt. Ulhas Kashalkar)."
            image="/images/tara.jpg"
            credit=""
            link="/learn-hindustani-music-online"
            buttonText="LEARN WITH TARA"
            follow="Follow Tara's work:-"
            followLink="https://www.youtube.com/channel/UCt0D0_IctIYDpTivpKrqO3g"
            pdf="/tara-kanan-bio.pdf"
          />
        </div>
      </div>
      {/* counters section */}
      <CounterSection></CounterSection>
      <div className="w-full py-20 bg-performingBg bg-cover bg-center bg-no-repeat flex flex-col justify-center items-center">
        <h2 className="text-white lg:text-5xl text-3xl font-bold w-10/12 mb-8 text-center">
          Kaladhwani is the top performing arts learning platform for young
          minds.
        </h2>
        <Button type="internalLink" to="/book-free-trial">
          Book a FREE Trial
        </Button>
      </div>
    </Layout>
  );
};

export default IndexPage;
