
import React, { useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { motion, AnimatePresence } from "framer-motion"
import { FaReact } from "@react-icons/all-files/fa/FaReact";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";
import { RiTeamFill } from "@react-icons/all-files/ri/RiTeamFill";
import { IoApertureOutline } from "@react-icons/all-files/io5/IoApertureOutline";
import { GiMountaintop } from "@react-icons/all-files/gi/GiMountaintop";
import { BsFlagFill } from "@react-icons/all-files/bs/BsFlagFill";
// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode"
import "swiper/css/pagination"

import "./swiper.css";

// import Swiper core and required modules
import SwiperCore, {
    FreeMode, Pagination, Navigation
} from 'swiper';

// install Swiper modules
SwiperCore.use([FreeMode, Pagination, Navigation]);


export default function WhySection() {

    const whyContent = [
        {
            "id": 1,
            "title": "Learning Practical and Theory",
            "image": "/images/practical.png",
            "description": "Performance and Theory, are both essential in an art form and thus, we focus on teaching this, simultaneously for the students to work and think like artists, right from the beginning, unlike other dance schools.",
            "icon": "<FaReact/>",
        },
        {
            "id": 2,
            "title": "Progress Tracking ",
            "image": "/images/progress-tracking.png",
            "description": "Progress Tracking at Kaladhwani is conducted on a quarterly basis. We video the students' performance fortnightly to capture learning to enable parents and the student realize how much improvement they have made. Progress Tracking  is not looked at as a goal, but a positive progress of the learning journey of a child.",
            "icon": "<RiTeamFill/>",
        },
        {
            "id": 3,
            "title": "Workshops at Kaladhwani",
            "image": "/images/workshop.png",
            "description": "It is not just classroom teaching.   The Workshops Organized, are free for active students enrolled at Kaladhwani. These range from Movement, Rhythm, Music, Poetry, Mythology and Aesthetics, which will broaden their perspective.",
            "icon": "<IoApertureOutline/>",
        },
        {
            "id": 4,
            "title": "Individual Mentorship",
            "image": "/images/mentorship.png",
            "description": "We  encourage and are happy to guide,If a student wishes to perform a composition (at a festival or an event), he/she will be given individual mentorship for the performance.",
            "icon": "<FaGlobe/>",
        },
        {
            "id": 5,
            "title": "Meet the Legends",
            "image": "/images/legends.png",
            "description": "For the evolution of the child, emphasis is not just given on classroom teaching, but to make the child aware of the history and tradition of each art form. The students will get an opportunity to interact with the stalwarts in the field of Classical Dance and Music.",
            "icon": "<GiMountaintop/>",
        },
        {
            "id": 6,
            "title": "Performance Features",
            "image": "/images/performance.png",
            "description": "We take pride in our students' learning. Keeping this in view, an Annual showcase  is organized to provide a platform to the students. We ensure they experience the performance process and specifically train them for competitive as well as cultural events.",
            "icon": "<BsFlagFill/>",
        },
    ];
    const [currentRow, setCurrentRow] = useState(whyContent[0]);
    const renderSwitch = (param, rowId, currentRowId) => {
        switch (param) {
            case '<FaReact/>':
                return <FaReact className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            case '<RiTeamFill/>':
                return <RiTeamFill className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            case '<IoApertureOutline/>':
                return <IoApertureOutline className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            case '<FaGlobe/>':
                return <FaGlobe className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            case '<GiMountaintop/>':
                return <GiMountaintop className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            case '<BsFlagFill/>':
                return <BsFlagFill className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
            default:
                return <FaReact className={`${rowId === currentRowId ? 'w-6 h-6 text-white' : 'w-5 h-5 text-black'}`} />;
        }
    };
    return (
        <>
            <Swiper slidesPerView={1} autoHeight={true} spaceBetween={5} freeMode={true} navigation={true} pagination={{
                "clickable": true
            }} className="myWhySlider">
                {
                    whyContent.map((row, index) => (
                        <SwiperSlide>
                            <div className="flex lg:h-auto md:h-28 h-40 cursor-pointer learnRow" onClick={() => setCurrentRow(row)}>
                                <div className="flex flex-col justify-start items-center tab w-12 relative">
                                    <div className={`${row.id == currentRow.id ? 'w-12 h-12 bg-black' : 'w-8 h-8 bg-lightOrange'} z-1 transition-all duration-300 ease rounded-full flex justify-center flex-col items-center`}>
                                        {
                                            renderSwitch(row.icon, row.id, currentRow.id)
                                        }
                                    </div>
                                    <div className="absolute left-6 z-0 h-full w-1px bg-white line">
                                    </div>
                                </div>
                                <div className="flex flex-col pl-8 group w-tabRow">
                                    <div className={`${row.id == currentRow.id ? 'h-12' : 'h-8'} flex items-start transition-all duration-300 ease`}>
                                        <h3 className={`font-bold transition-all duration-300 ease w-auto group-hover:text-black mt-0 md:mt-0 ${row.id == currentRow.id ? 'text-xl text-black h-12' : 'md:text-lg text text-white h-8'} md:leading-snug leading-none flex items-center`}>{row.title}</h3>
                                    </div>

                                    <p className={`text-sm transition-all pb-4 ${row.id == currentRow.id ? 'md:-mt-2' : 'md:mt-0'} leading-none duration-300 ease group-hover:text-black ${row.id == currentRow.id ? 'text-black' : 'text-white'}`}>{row.description}</p>
                                </div>
                            </div>
                        </SwiperSlide>
                    ))
                }
            </Swiper>
        </>
    )
}