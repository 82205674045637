import React from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerSlide from './BannerSlide';
import { AiOutlineRight } from "@react-icons/all-files/ai/AiOutlineRight";
import { RightChevron } from "../../utils/icons/right-chevron";
import { LeftChevron } from "../../utils/icons/left-chevron";
function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="absolute inset-y-0 lg:right-20 md:right-4 right-2 md:w-12 w-8 h-full justify-end items-center z-1" style={{ display: props.onClick === null ? "none" : "flex" }}>
            <RightChevron className="cursor-pointer" fill="#A5A5A5" onClick={onClick} />
            {/* <AiOutlineRight className="cursor-pointer text-3xl" onClick={onClick} /> */}
        </div>
    );
}

function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
        <div className="absolute inset-y-0 lg:left-20 md:left-4 left-2 md:w-12 w-8 h-full justify-start items-center z-1" style={{ display: props.onClick === null ? "none" : "flex" }}>
            <LeftChevron className="cursor-pointer" fill="#A5A5A5" onClick={onClick} />
        </div>
    );
}
export default function BannerSlider(props) {

    const settings = {
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        swipeToSlide: true,
        // fade: true,
        speed: 1000,
        autoplay: true,
        autoplaySpeed: 5000,
        appendDots: dots => (
            <div>
                <ul className="m-0"> {dots} </ul>
            </div>
        ),
        customPaging: i => (
            <div
                style={{
                    width: "100%",
                    height: "100%",
                    color: "#000",
                    background: "#fff",
                }}
                className=""
            >
            </div>
        ),
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />
    };
    return (
        <div className={`flex flex-col justify-start items-start w-full`}>

            <Slider {...settings} className="w-full">
                <BannerSlide image="md:bg-homeBanner1 bg-mobileBanner1"></BannerSlide>
                <BannerSlide image="md:bg-homeBanner2 bg-mobileBanner2"></BannerSlide>
                <BannerSlide image="md:bg-homeBanner3 bg-mobileBanner3"></BannerSlide>
                <BannerSlide image="md:bg-homeBanner4 bg-mobileBanner4"></BannerSlide>
            </Slider>
        </div>
    )
}
